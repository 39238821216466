import _uniq from 'lodash/uniq'
import _sortBy from 'lodash/sortBy'
import _map from 'lodash/map'
import _reduce from 'lodash/reduce'
import _flow from 'lodash/flow'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import _parseISO from 'date-fns/parseISO'
import _startOfDay from 'date-fns/startOfDay'

import { getImageURL, toPlainText } from 'utils/sanity'
import { getInternalLink } from 'utils/helpers'
import { useMemo } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export const BasePath = '/open-programs'

export const palette = {
  variant: 'secondaryAlpha',
  accent: 'secondaryBeta',
}

const getNextRun = (programRuns) => {
  const activeRuns = _reduce(
    programRuns,
    (acc, run) => {
      if (run.active === false) return acc

      const modules = _flow(
        (module) => _filter(module, ['active', true]),
        (module) => _sortBy(module, 'startDate')
      )(run.modules)

      const output = { ...run, modules }
      acc.push(output)

      return acc
    },
    []
  )

  if (activeRuns.length === 0) return null

  return _sortBy(activeRuns, (run) => run.modules[0]?.startDate)[0]
}

export const buildNextRun = (program, locale) => {
  const runs = _get(program, 'programRuns', [])
  const nextRun = getNextRun(runs)

  if (!runs.length || !nextRun || !nextRun.modules.length) return null

  const { startDate } = nextRun.modules[0]
  const { endDate } = nextRun.modules[nextRun.modules.length - 1]
  const today = _startOfDay(new Date())

  const fees =
    nextRun.feeRegular &&
    nextRun.feeRegular.toLocaleString(locale === 'en' ? 'en-US' : 'de-DE', {
      style: 'currency',
      currency: 'EUR',
    })
  const feeEarlyBird =
    nextRun.feeEarlyBird &&
    nextRun.feeEarlyBird.toLocaleString(locale === 'en' ? 'en-US' : 'de-DE', {
      style: 'currency',
      currency: 'EUR',
    })
  const earlyBirdDateParsed = _parseISO(nextRun.earlyBirdDate)
  const earlyBirdDate =
    earlyBirdDateParsed.toString() === 'Invalid Date'
      ? undefined
      : earlyBirdDateParsed
  const earlyBirdActive = earlyBirdDate && earlyBirdDate >= today

  const feeSuperEarlyBird =
    nextRun.feeSuperEarlyBird &&
    nextRun.feeSuperEarlyBird.toLocaleString(
      locale === 'en' ? 'en-US' : 'de-DE',
      { style: 'currency', currency: 'EUR' }
    )
  const superEarlyBirdDateParsed = _parseISO(nextRun.superEarlyBirdDate)
  const superEarlyBirdDate =
    superEarlyBirdDateParsed.toString() === 'Invalid Date'
      ? undefined
      : superEarlyBirdDateParsed
  const superEarlyBirdActive = superEarlyBirdDate && superEarlyBirdDate >= today

  const applicationDeadlineParsed = nextRun.application_deadline
    ? _parseISO(nextRun.application_deadline)
    : undefined

  const locations = _flow(
    (d) => _map(d, 'location'),
    (d) => _uniq(d)
  )(nextRun.modules)

  return {
    startDate,
    endDate,
    locations,
    fees,
    feeEarlyBird,
    earlyBirdDate,
    earlyBirdActive,
    feeSuperEarlyBird,
    superEarlyBirdDate,
    superEarlyBirdActive,
    applicationDeadlineParsed,
  }
}

export const buildCategoryProgramMap = (categories, programs) => {
  return _flow(
    (data) => _sortBy(data, ['sort', 'title']),
    (data) =>
      _map(data, (category) => ({
        id: category.id,
        title: category.title,
        programs: _flow(
          (data) =>
            _filter(data, (program) => program.category?.id === category.id),
          (data) => _sortBy(data, ['title'])
        )(programs),
      }))
  )(categories)
}

export const setApplicationURL = (program) => {
  if (program.onlineApplication) {
    if (program.onlineApplicationUrl) {
      return program.onlineApplicationUrl
    }

    return 'https://application-center.whu.edu/onboarding/programs/'
  }

  if (program.externalApplication) {
    return program.externalApplication
  }

  if (program.applicationForm?.asset?.url) {
    let title = program.applicationForm?.asset?.originalFilename

    if (!title) {
      const slug = getInternalLink(program, 'en')
      title = `whu_${slug}_application_form.pdf`
    }

    return `${program.applicationForm.asset.url}?dl=${encodeURIComponent(
      title
    )}`
  }

  return undefined
}

/**
 * @param {object|undefined} field
 * @param {i18n} i18n
 * @param {boolean} fallback
 * @returns {undefined|string}
 */
const localeFieldToString = (field, i18n, fallback = true) => {
  return field?.[i18n.language] ?? (fallback ? field?.en : undefined)
}

export const useSeo = (program, i18n) => {
  const nextRun = getNextRun(program.programRuns)

  return useMemo(() => {
    let offers = undefined
    if (nextRun) {
      offers = {
        '@type': 'Offer',
        priceCurrency: 'EUR',
        price: nextRun.feeRegular,
      }
    }

    return {
      name: program.title,
      description: localeFieldToString(program?.meta?.description, i18n),
      overrides: {
        offers,
        provider: {
          '@type': 'CollegeOrUniversity',
          name: 'WHU - Otto Beisheim School of Management',
          url: 'https://ee.whu.edu/',
        },
      },
    }
  }, [program, nextRun, i18n])
}

export const useOpenGraph = (program, i18n) => {
  const intro = program._rawIntro[i18n.language] ?? program._rawIntro.en

  const imageURL = getImageURL(program, 'hero.asset._id')
  const imageAlt = program?.hero?.alt?.localized
  return {
    title:
      localeFieldToString(program?.meta?.title, i18n, false) ?? program.title,
    description:
      localeFieldToString(program?.meta?.description, i18n) ??
      toPlainText(intro),
    openGraph: {
      title: program.title,
      description: toPlainText(intro),
      images: imageURL
        ? [
            {
              width: 800,
              height: 600,
              url: imageURL,
              alt: imageAlt ?? program.title,
            },
          ]
        : undefined,
    },
  }
}

export const makeCallScheduleLink = (program) => {
  let title = program.title?.trim()

  if (!title || !program.callScheduleUrl) {
    return undefined
  }

  title = encodeURIComponent(title)

  return `${program.callScheduleUrl}?subject="${title}&SO_ExecEd_Which_program_are_you_interested_in=${title}`
}
